<template>
  <div>
    <Breadcrumbs title="Teacher Settlement Reports"/>
    <div class="container-fluid" v-if="loaded">

      <form @submit.prevent="fetchData" class="card">
        <div class="card-body">

          <b-row>
            <b-col cols="12" md="3">
              <div class="form-group">
                <label class="control-label">Institute <span class="font-danger">*</span></label>
                <b-form-select :disabled="!institutes.length" @change="fetchClasses" :state="hasError('institute_id')" :options="institutes" value-field="id" text-field="name" class="btn-square border" v-model="formSearch.institute_id"></b-form-select>
                <b-form-invalid-feedback :state="hasError('institute_id')">{{getError('institute_id')}}</b-form-invalid-feedback>
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <div class="form-group">
                <label class="control-label">Lecturer <span class="font-danger">*</span></label>
                <b-form-select class="btn-square border" @change="fetchClasses" v-model="formSearch.teacher_id" :options="lecturers" value-field="id" text-field="text"></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <div class="form-group">
                <label class="control-label">Class</label>
                <b-form-select @change="clearReportData" class="btn-square border" v-model="formSearch.class_detail_id" :options="class_details" value-field="id" text-field="text"></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" md="1">
              <b-button :disabled="!formSearch.teacher_id || isBusy" class="btn-square m-r-10 m-t-30 text-uppercase" variant="dark" @click="generateReport">Submit</b-button>
            </b-col>
            <b-col cols="12" md="1">
              <b-button :disabled="!totalRows || isBusy" class="btn-square m-r-10 m-t-30 text-uppercase" variant="danger" @click="settlePayments">Settle</b-button>
            </b-col>
          </b-row>

        </div>
      </form>


      <div class="card">
        <div class="card-body">

          <div class="text-center">
            <h2 class="card-title">Teacher Settlement Report (Unsettled)</h2>
          </div>
          <b-row>
            <b-col cols="4" md="3" lg="2">Institute</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ institutes.filter(institute => institute.id===formSearch.institute_id).map(institute => institute.name)[0] }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Lecturer</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ lecturers.filter(user => user.id===formSearch.teacher_id).map(user => user.text)[0] }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Class</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ class_details.filter(cls => cls.id===formSearch.class_detail_id).map(cls => cls.text)[0] }}</b-col>
          </b-row>

          <div class="table-responsive datatable-vue" v-if="!isBusy">
            <b-table
                ref="table"
                show-empty
                selected-variant="success"
                :items="paymentRecords"
                :fields="tableFields"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
            >
              <template #cell(index)="row">{{ 1+row.index }}</template>
              <template #cell(id)="row">{{ row.item.id }} {{row.item.invoice_id}}</template>
              <template #cell(cashier_name)="row">
                <div>{{ row.item.creator_first_name }} {{ row.item.creator_last_name }}</div>
              </template>
              <template #cell(teacher_name)="row">
                <div>{{ row.item.user_first_name }} {{ row.item.user_last_name }}</div>
                <div>( {{ row.item.user_nic }} )</div>
              </template>
              <template #cell(created_at)="row">
                <p class="m-0">{{ moment(row.item.created_at).format('ll') }}</p>
                <p class="m-0">{{ moment(row.item.created_at).format('h:s A') }}</p>
              </template>
              <template #cell(amount)="row">Rs. {{ row.item.amount }}</template>
            </b-table>
          </div>


          <b-row>
            <b-col cols="4" md="3" lg="2">No of Payments</b-col>
            <b-col cols="8" md="9" class="text-left">: {{ totalRows }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Total</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ report_total }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Institute Share</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ institute_share }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="4" md="3" lg="2">Teacher Share</b-col>
            <b-col cols="8" md="9" class="text-left">: Rs. {{ teacher_share }}</b-col>
          </b-row>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import API from "@/services/api";
export default {
  name: "settlements",
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
    lecturerSearch: function (lecturer) { this.fetchLecturers(lecturer) },
  },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    institutes() {
      let institute_list = this.user.institutes
      institute_list.unshift({
        id: null,
        name: "All Institutes"
      })
      return institute_list
    }
  },
  mounted() {
    this.fetchCashiers();
    this.fetchLecturers();
    this.fetchCashiers();
  },
  data() {
    return {
      // api_base: '/api/backend/reports/teacher-settlements/',
      api_base: '/api/backend/pages/settlements/',
      errorBag: null,
      filter: '',
      totalRows: 0,
      perPage: 0,
      currentPage: 1,
      formSearch: {
        teacher_id: null,
        creator_id: null,
        institute_id: null,
        class_detail_id: null,
        settlement_date: '',
        class_list: []
      },
      paymentRecords: [],
      settlementStatus: 1,
      tableFields: [
        { key: 'index', label: '#', sortable: false },
        { key: 'id', label: 'Payment ID', sortable: false },
        { key: 'created_at', label: 'Date / Time', sortable: true },
        { key: 'institute_name', label: 'Institute', sortable: true },
        { key: 'teacher_name', label: 'Student', sortable: true },
        { key: 'cashier_name', label: 'Cashier', sortable: true },
        { key: 'payment_method', label: 'Payment Method', sortable: false },
        { key: 'institute_share', label: 'Institute Share', sortable: false, class: 'text-right' },
        { key: 'teacher_share', label: 'Teacher Share', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Net Amount', sortable: false, class: 'text-right' },
      ],

      loaded: false,
      isBusy: false,
      tableRowIndex: 0,
      cashiers: [],
      lecturers: [],
      class_details: [{id:null,text:"All Classes"}],
      lecturerSearch: '',
      selectedLecturer: null,



      settleForm:[],
      settlements: [],
      report_total: 0,
      institute_share: 0,
      teacher_share: 0,
      lecturer_name: 'N/A',
      summaryTableFields: [
        { key: 'id', label: "", class: 'text-left' },
        { key: 'value', label: "", class: 'text-right' },
      ],
      summaryTableRecords: [
        { id: "Total: ", value: 0 },
        { id: "Institute Share: ", value: 0 },
        { id: "Teacher Share: ", value: 0 },
      ],
    }
  },
  methods: {
    checkUserPermissions,
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },

    changeTeacher(){
      this.formSearch.class_detail_id = null
      this.clearReportData()
      this.fetchClasses()
    },
    clearReportData(){
      clearTimeout()
      this.paymentRecords = []
      this.settlements = []
      this.settleForm = []
      this.isBusy = false
    },
    async fetchCashiers(){
      this.loaded = false
      API.select('cashiers')
          .then((result) => {
            this.cashiers = result.data.data
            this.loaded = true
          }).catch((error)=>{
        console.log(error)
        this.cashiers = []
        this.errorBag = error.response.data.data
      }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.cashiers.unshift({
          id: null,
          text: "All Cashiers"
        })
      })
    },
    async fetchLecturers(query) {
      API.select('teachers', { filter: query })
          .then((response) => {
            this.lecturers = response.data.data
            if (this.lecturers.length === 1) {
              this.formSearch.teacher_id = this.lecturers[0].id
              this.fetchClasses()
            }
          })
          .catch((error) => {
            console.log(error)
            this.lecturers = []
            this.$toasted.error(error.message)
          }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.lecturers.unshift({
          id: null,
          text: "All Lecturers"
        })
      })
    },
    async fetchClasses(){
      this.clearReportData()
      API.select('classes', {
        'teacher_id': this.formSearch.teacher_id,
        'institute_id': this.formSearch.institute_id,
        'settlement_date': this.formSearch.settlement_date
      }).then((result) => {
        this.class_details = result.data.data
        console.log("CLASSES", result.data)
      }).catch((error) => {
        console.log("CLASSES", error)
        this.class_details = []
        this.$toasted.error("Classes could not be retrieved")
      }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.class_details.unshift({
          id: null,
          text: "All Classes"
        })
      })
    },
    async generateReport() {
      this.paymentRecords = []
      this.currentPage = 0
      this.totalRows = 0
      this.institute_share = 0
      this.teacher_share = 0
      this.report_total = 0
      this.loading = true
      this.isBusy = true

      await API.post(this.api_base +'summary', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
        class_detail_id: this.formSearch.class_detail_id,
        start_date: this.formSearch.start_date,
        end_date: this.formSearch.end_date,
        status: 0
      }).then(result => {
        if(result.data.success) {
          this.totalRows = result.data.data.record_count
          this.report_total = result.data.data.amount_total
          this.institute_share = result.data.data.institute_share
          this.teacher_share = result.data.data.teacher_share
          this.lastPage = (this.totalRows / this.perPage)

          this.summaryTableRecords = [
            { id: "Total: ", value: this.report_total },
            { id: "Institute Share: ", value: this.institute_share },
            { id: "Teacher Share: ", value: this.teacher_share },
          ]

          clearTimeout()
          if(this.totalRows > 0) {
            // this.fetchData()
            setInterval(() => {
              if (this.currentPage < this.lastPage) {
                this.currentPage += 1
                this.fetchData()
              } else {
                clearTimeout()
                this.loading = false
                this.isBusy = false
              }
            }, 1000)
          } else {
            this.loading = false
            this.isBusy = false
          }

        } else {
          this.$toasted.error(result.data.message)
        }

      }).catch(error => {
        console.log(error)
        this.$toasted.error(error.message)
      })
    },
    async fetchData(){
      await API.post(this.api_base + 'data', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
        class_detail_id: this.formSearch.class_detail_id,
        start_date: this.formSearch.start_date,
        end_date: this.formSearch.end_date,
        page: this.currentPage,
        per_page: 500,
        status: 0,
      }).then((response) => {
        if(response.data.success) {

          this.lastPage = response.data.data.last_page
          this.perPage = response.data.data.total
          this.totalRows = response.data.data.total
          this.paymentRecords = this.paymentRecords.concat(response.data.data.data)

          // this.paymentRecords = response.data.data.data
          // this.totalRows = response.data.data.total
          if (!this.totalRows) {
            this.$toasted.success("No Records found.")
          }
        } else {
          this.errorBag = response.data.data
          this.$toasted.error(response.data.message)
        }
      }).catch((errors) => {
        clearTimeout()
        // console.log(errors)
        // this.paymentRecords = []
        this.errorBag = errors.data.data
        this.$toasted.error("Error occurred during data retrieval.")
      })
    },
    async settlePayments() {
      //this.settlements = this.paymentRecords.filter(pay => pay.status===0).map(pay => { return pay })
      this.settleForm = {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
        class_detail_id: this.formSearch.class_detail_id,
        start_date: this.formSearch.start_date,
        end_date: this.formSearch.end_date,
        //settlements: this.settlements,
        teacher_share: this.teacher_share,
        institute_share: this.institute_share,
      };
      await API.post(this.api_base + 'settle', this.settleForm)
          .then(result => {
            if(result.data.success) {
              this.$toasted.success(result.data.message)
              this.$router.push({ name: 'teacher-settlement-report-view', params: { settlementId: result.data.data.settlement.id }})
            } else {
              console.log(result.data)
              this.$toasted.error(result.data.message)
            }
          })
          .catch(error => {
            console.log(error)
            this.$toasted.error(error.message)
          })
    },
  },
}
</script>

<style scoped>

</style>
